<template>
  <div class="quotedPrice"  id="box" @click="portShow = false; zhouShow = false;codeShow = false">
      <div class="quotedPriceInner">
          <div class="quotedPriceFilter">
               <el-card class="box-card">
                   <div class="filterSearchDiv">
                        <el-form label-width="100px" :model="port" :rules="rules" ref="port" >
                            <el-row :gutter="20">
                                <el-col :span="6">
                                    <!-- <el-form-item label="起运地" prop="startPortId">
                                        <el-select v-model="port.startPortId" placeholder="请选择起运地">
                                            <el-option v-for="item in sList" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item> -->
                                    <div class="end" @click.stop="">
                                        <label class="lab">起运地</label>
                                        <div class="iptDiv" :class="portShow ? 'active' : ''">
                                            <div class="text">
                                                <input placeholder="请选择起运地" v-model="port.startPort" @click="portShow = !portShow">
                                            </div>
                                            <i class="el-icon-caret-bottom endIcon"></i>
                                        </div>
                                        <div class="modalBox" v-show="portShow">
                                            <div class="modalCont">
                                                <ul>
                                                    <li class="active">热门</li>
                                                </ul>
                                            </div>
                                            <div class="modalItem">
                                                <div class="countryItem">
                                                    <el-radio-group v-model="port.startPortId" @change="portChange">
                                                        <el-radio-button v-for="(item,index) in sList" :key="index" :label="item.value" >{{item.text}}</el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="end" @click.stop="">
                                        <label class="lab">目的地</label>
                                        <div class="iptDiv" :class="zhouShow ? 'active' : ''">
                                            <div class="text">
                                                <input placeholder="请选择目的地" v-model="port.country" @click="zhouShow = !zhouShow">
                                            </div>
                                            <i class="el-icon-caret-bottom endIcon"></i>
                                        </div>
                                        <div class="modalBox" v-show="zhouShow">
                                            <div class="modalCont">
                                                <ul>
                                                    <li :class="zhou == 1 ? 'active':''" @click="zhou = 1">北美洲</li>
                                                    <li :class="zhou == 2 ? 'active':''" @click="zhou = 2">大洋洲</li>
                                                    <li :class="zhou == 3 ? 'active':''" @click="zhou = 3">欧洲</li>
                                                    <li :class="zhou == 4 ? 'active':''" @click="zhou = 4">亚洲</li>
                                                    <li :class="zhou == 5 ? 'active':''" @click="zhou = 5">非洲</li>
                                                    <li :class="zhou == 6 ? 'active':''" @click="zhou = 6">南美洲</li>
                                                </ul>
                                            </div>
                                            <div class="modalItem">
                                                <div class="countryItem" v-show="zhou===1">
                                                    <el-radio-group v-model="port.countryId" @change="endCountry">
                                                        <el-radio-button v-for="(item,index) in BMZ" :key="index" :label="item.id" >{{item.label}}</el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div class="countryItem" v-show="zhou===2">
                                                    <el-radio-group v-model="port.countryId" @change="endCountry">
                                                        <el-radio-button v-for="(item,index) in DYZ" :key="index" :label="item.id" >{{item.label}}</el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div class="countryItem" v-show="zhou===3">
                                                    <el-radio-group v-model="port.countryId" @change="endCountry">
                                                        <el-radio-button v-for="(item,index) in OZ" :key="index" :label="item.id" >{{item.label}}</el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div class="countryItem" v-show="zhou===4">
                                                    <el-radio-group v-model="port.countryId" @change="endCountry">
                                                        <el-radio-button v-for="(item,index) in YZ" :key="index" :label="item.id" >{{item.label}}</el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div class="countryItem" v-show="zhou===5">
                                                    <el-radio-group v-model="port.countryId" @change="endCountry">
                                                        <el-radio-button v-for="(item,index) in FZ" :key="index" :label="item.id" >{{item.label}}</el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div class="countryItem" v-show="zhou===6">
                                                    <el-radio-group v-model="port.countryId" @change="endCountry">
                                                        <el-radio-button v-for="(item,index) in NMZ" :key="index" :label="item.id" >{{item.label}}</el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="5">
                                    <div class="endcode" @click.stop="">
                                        <label class="lab">FBA仓库(选填)</label>
                                        <div class="iptDiv" :class="codeShow ? 'active' : ''">
                                            <div class="text">
                                                <p @click="codeShow = !codeShow">
                                                    <span>{{FBAcode}}</span>
                                                </p>
                                            </div>
                                            <i class="el-icon-caret-bottom endIcon"></i>
                                        </div>
                                        <div class="modalBox" v-show="codeShow">
                                             <div class="modalCont">
                                                 <ul>
                                                     <li :class="code == 1 ? 'active':''" @click="code = 1">FBA仓库</li>
                                                     <li :class="code == 2 ? 'active':''" @click="code = 2">非FBA仓库</li>
                                                 </ul>
                                             </div>
                                             <div class="modalItem">
                                                <div class="countryItem" v-show="code===1">
                                                    <el-radio-group v-model="port.code" @change="codeChange">
                                                        <el-radio-button v-for="(item,index) in codeList" :key="index" :label="item.code" >{{item.code}}</el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div class="countryItem" v-show="code===2">
                                                    <el-input v-model="port.postCode" placeholder="请输入地区邮编"></el-input>
                                                    <div class="postCodeBtn">
                                                        <button type="button" class="cancel" @click="port.postCode = '';codeShow=false">取消</button>
                                                        <button type="button" class="sure" @click="FBAcode = port.postCode; codeShow=false">确定</button>
                                                    </div>
                                                </div>
                                             </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="5">
                                    <div class="endcode wOv">
                                        <input type="text" placeholder="输入重量或体积" v-model="port.weight">
                                        <select v-model="port.unit">
                                            <option value="0">KG</option>
                                            <option value="1">CBM</option>
                                        </select>
                                    </div>
                                </el-col>
                                <el-col :span="2">
                                    <div class="filterBtn">
                                        <el-button type="success" @click="queryPrice(10)">查询</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div class="filterMore">
                         <el-row :gutter="10">
                              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                  <label for="">是否包税: </label>
                                  <el-radio-group v-model="includeTariff" @change="queryPrice(pageSize)">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="0">包税</el-radio-button>
                                    <el-radio-button label="1">不包税</el-radio-button>
                                  </el-radio-group>
                              </el-col>
                              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                  <label for="">运输方式: </label>
                                  <el-radio-group v-model="transportWay" @change="queryPrice(pageSize)">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="0">海运</el-radio-button>
                                    <el-radio-button label="1">铁运</el-radio-button>
                                    <el-radio-button label="2">快递</el-radio-button>
                                    <el-radio-button label="3">空运</el-radio-button>
                                  </el-radio-group>
                              </el-col>
                              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                  <label for="">货物类型: </label>
                                  <el-radio-group v-model="goodsType" @change="queryPrice(pageSize)">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="0">普货</el-radio-button>
                                    <el-radio-button label="1">带电带磁</el-radio-button>
                                    <el-radio-button label="2">带磁</el-radio-button>
                                    <el-radio-button label="3">带电</el-radio-button>
                                    <el-radio-button label="4">弱磁</el-radio-button>
                                  </el-radio-group>
                              </el-col>
                         </el-row>
                    </div>
               </el-card>
               <div class="sortDiv">
                <el-radio-group v-model="sort" @change="queryPrice(pageSize)">
                    <el-radio-button label="all">综合排序</el-radio-button>
                    <el-radio-button label="price">价格最低</el-radio-button>
                    <el-radio-button label="effectTime">时效最快</el-radio-button>
                </el-radio-group>
               </div>
          </div>
          <div class="box">
            <div class="quotedPriceItem infinite-list-wrapper" v-for="(item ,index) in quotedPriceList" :key="index">
                <el-card class="box-card" shadow="hover">
                    <el-row>
                        <el-col :span="2">
                            <div class="logo">
                                <img src="../../../assets/partner/fanyuan.png" alt="">
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="cont-middle">
                                <h3>
                                    {{item.channelName}}({{item.includeTariff == 0 ? '包税' : '不包税'}})
                                </h3>
                                <div class="cont-middle-ot">
                                    <span>
                                        <label>时效:</label>
                                        {{item.effectTime}}工作日
                                    </span>
                                    <span>
                                        <label>开船:</label>
                                        {{item.cutOffTime}}
                                    </span>
                                    <span>
                                        <label>货物类型:</label>
                                        {{ item.goodsType == 0 ? '普货' : item.goodsType == 1 ? '带点带磁' : item.goodsType == 2 ? '带磁' : item.goodsType == 3 ? '带电' : '弱磁' }}
                                    </span>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="cont-price">
                                <p style="padding-right:10px">
                                    单价:
                                    <span>
                                        ￥<i>{{item.freight[0].price}}</i>/{{item.unit == 0 ? 'KG' : 'CBM'}}
                                    </span>
                                    起
                                    <el-popover
                                        placement="left"
                                        trigger="hover">
                                        <table class="fqPriceTab">
                                            <tr>
                                                <th>分区</th>
                                                <th v-for="(tth,index) in item.newPriceArr[0]" :key="index">
                                                    {{tth.startNumber}}{{tth.endNumber ? '-'+tth.endNumber : '以上' }}{{item.unit == 0 ? 'KG' : 'CBM'}}
                                                </th>
                                            </tr>
                                            <tr v-for="(ttd,index) in item.newPriceArr" :key="index">
                                                <td>{{ttd[0].area.name}}</td>
                                                <td v-for="(it,ind) in ttd" :key="ind">{{it.price}}</td>
                                            </tr>
                                        </table>
                                        <i class="el-icon-question" slot="reference"></i>
                                    </el-popover>
                                </p>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="cont-right">
                                <p>
                                    <button type="button" @click="placeOrder()">立即下单</button>
                                </p>
                                <p>
                                <span @click="toDetail(item)"><i class="el-icon-notebook-2"></i> 方案详情</span> 
                                </p>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
             <p v-if="loading">加载中...</p>
            <p v-if="noMore">
                    <el-divider>没有更多了</el-divider>
            </p>
          </div>
      </div>
  </div>
</template>

<script>
    import quotedPrice from './quotedPrice.js';
    export default quotedPrice;
</script>

<style scoped>
    @import './quotedPrice.css';
</style>