import quotedPrice from './quotedPrice.vue'
import axios from 'axios'
export default {
    components: {
        quotedPrice
    },
    data() {
        return {
            quotedPriceList: [],
            sList:[
                { text: '上海',value:599 },
                { text: '厦门',value:627 },
                { text: '宁波',value:575 },
                { text: '青岛',value:590},
                { text: '广州',value:554 },
                { text: '天津',value:620},
                { text: '大连',value:543 },
                { text: '福州',value:552},
                { text: '香港',value:1564 },
                { text: '台北',value:618 },
                { text: '高雄',value:3269 },
                { text: '基隆',value:3266 },
                { text: '台中',value:3271 },
                { text: '蛇口',value:3697 },
                { text: '盐田',value:635 },
                { text: '釜山',value:3116 },
                { text: '仁川',value:3123 },
            ],//起运港列表
            portShow:false,
            countryList:[],
            port: {
                startPort:'上海',
                startPortId: '599',
                country: '美国',
                countryId: '4',
                countryCode:'US',
                code: '全部',
                postCode: '',
                weight: '',
                unit: '0'
            },
            rules: {
                startPortId: [
                    { required: true, message: '请选择起运地', trigger: 'change' }
                ],
                country: [
                    { required: true, message: '请选择目的地', trigger: 'change' }
                ],
            },
            includeTariff: 'all',
            transportWay: 'all',
            goodsType: 'all',
            zhou: 1,
            zhouShow: false,
            BMZ: [
                { label: '加拿大', id: 52, code:'CA'},
                { label: '美国', id: 4, code:'US' },
                
            ],
            DYZ: [
                {label: '澳大利亚',id: 10},
                {label: '新西兰',id: 35}
            ],
            OZ: [
                {label: '法国',id: 8},
                {label: '爱尔兰',id: 214}
            ],
            YZ: [
                {label: '韩国',id: 146},
                {label: '日本',id: 67}
            ],
            FZ: [
                {label: '埃及',id: 112},
                {label: '加纳',id: 51}
            ],
            NMZ: [
                {label: '巴西',id: 135},
                {label: '哥伦比亚',id: 74}
            ],
            codeShow: false,
            code: 1,
            codeList: [],
            FBAcode: '全部',
            sort: 'all',
            loading: false,
            noMore: false,
            pageSize: 10,
        }
    },
    created() { 
        this.queryPrice()
    },
    methods: {
        handleScroll() {
      // scrollTop 滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // windowHeight 可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // scrollHeight 滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      // 滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight && this.noMore == false) {
        // 加载数据 
            this.pageSize = this.pageSize + 10
            this.queryPrice(this.pageSize,'scoll');
      }
        },
        placeOrder() {
            this.$router.push('/FBAOrder')
        },
        queryPrice(pageSize,type) {
            var data = {
                page: 1,
                pageSize: pageSize || 10
            }
            data.startPortId = this.port.startPortId ? this.port.startPortId : null
            data.countryId = this.port.countryId ? this.port.countryId : null
            data.includeTariff = this.includeTariff == 'all' ? null : this.includeTariff
            data.transportWay = this.transportWay == 'all' ? null : this.transportWay
            data.goodsType = this.goodsType == 'all' ? null : this.goodsType
            data.weight = this.port.weight ? this.port.weight : null
            data.unit = this.port.unit
            data.sort = this.sort == 'all' ? null : this.sort
            axios.get(this.GLOBAL.BASE_URL4 + '/client/fba/fbaprice', {
                params: data
            })
            .then(res => {
                if (res.data.result.rows.length <= this.pageSize && type == 'scoll') {
                    this.noMore = true
                    this.pageSize = this.pageSize -10
                } else {
                    this.noMore = false
                }
                this.quotedPriceList = res.data.result.rows
                this.quotedPriceList.forEach(item=> {
                    if (item.freight) {
                        const sortClass = (sortData) => {
                            const groupBy = (array, f) => {
                              let groups = {};
                              array.forEach((o) => {
                                let group = JSON.stringify(f(o));
                                groups[group] = groups[group] || [];
                                groups[group].push(o);
                              });
                                return Object.keys(groups).map((group) => {
                                    return groups[group].sort((a, b) => { return a.startNumber - b.startNumber});
                              });
                            };
                            const sorted = groupBy(sortData, (item) => {
                              return item.area.name; // 返回需要分组的对象
                            });
                            return sorted;
                        };
                        item.newPriceArr = sortClass(item.freight)
                       
                    }
                });
            })
            .catch(error => {
                console.log(error);
            })
        },
        // 目的地模糊查询
        queryCountry(val) { 
            axios.get(this.GLOBAL.BASE_URL4 + '/client/getCountry/like',{
                params:{
                    name: val
                }
            })
            .then(res => {
                if(res.data.data.length != 0){
                    this.countryList = res.data.data
                }else {
                    this.countryList = []
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 起运地选择
        portChange(val) {
            // this.port.startPortId = val
            this.sList.map(item => {
                if (item.value == val) {
                    this.port.startPort = item.text
                    this.portShow = false
                }
            })
         },
        // 国家选择
        endCountry(val) {
            // this.port.countryId = val
            var arr = []
            this.zhou == 1 ? arr = this.BMZ : 
                this.zhou == 2 ? arr = this.DYZ :
                    this.zhou == 3 ? arr = this.OZ :
                        this.zhou == 4 ? arr = this.YZ :
                            this.zhou == 5 ? arr = this.FZ : arr = this.NMZ
            arr.map(item => {
                if (item.id == val) {
                    this.port.country = item.label
                    this.port.countryCode = item.code
                    this.zhouShow = false
                }
            })
            if (this.port.countryCode) {
                axios.get(this.GLOBAL.BASE_URL4 + '/client/fba/fbacode', {
                    params: {
                        contryCode: this.port.countryCode
                    }
                })
                .then(res => {
                    console.log(res);
                    this.codeList = res.data.result
                })
                .catch(error => {
                    console.log(error);
                })
            }
        },
        // 仓库选择
        codeChange(val) { 
            console.log(val);
            this.port.code = val
            this.FBAcode = val
            this.codeShow = false
        },
        // 方案详情
        toDetail(item) {
            this.$router.push({path:'/planDetails', query: {id: item.id}})
        }
    },
    
    mounted() { 
        window.addEventListener("scroll",this.handleScroll); 
    },
    destroyed() {
        document.removeEventListener('scroll', this.handleScroll)
      }
}